import VueRouter from 'vue-router'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import BackPage from './pages/BackPage'
import ArticleListPage from './pages/ArticleListPage'
import ArticlePage from './pages/ArticlePage'
export default new VueRouter({
	routes:[
    {
    name:'HomePage',
		path:'/',
		component:HomePage
    },
    {
      name:'LoginPage',
      path:'/login',
      component:LoginPage
    },
    {
      name:'BackPage',
      path:'/back',
      component:BackPage,
      children:[
        {
          name:'ArticleList',
          path:'article_list',
          component:ArticleListPage
        },{
          name:'Article',
          path:'article',
          component:ArticlePage,
          props($route){
            return $route.query
          }
        }
      ]
    },
  ]
})

