<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script>
import Vue from 'vue'
import router from './router'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default {
  name: 'App',
  data(){
    return {
      xx1: localStorage.getItem('xx1')
    }
  },
  components: {
  },
  beforeCreate(){
    Vue.prototype.$bus = this
    Vue.prototype.$api = 'https://spback.feianmo.com/api'
    this.$bus.$on('app_get_xx1',(xx1)=>{
      this.xx1 = xx1
    })
    this.$bus.$on('app_send_xx1',(obj)=>{
      obj.xx1 = this.xx1
    })
  },
  mounted(){
    // this.$bus.$emit('get_app', this)
    // this.$bus.$emit('get_xx1', this.xx1)
  },
  router,
}
</script>

<style>
</style>
