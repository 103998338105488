<template>
  <div class="mt-3">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h3>编辑内容</h3>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button type="button" class="btn btn-sm btn-outline-secondary">保存本地</button>
          <button 
            type="button" 
            @click="saveCloud"
            class="btn btn-sm btn-outline-secondary">保存线上</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataBase">
      <div class="col-md-6 my-1">
        <small>标题:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.title" />
      </div>
      <div class="col-md-3 my-1">
        <small>站点id:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.site_id" />
      </div>
      <div class="col-md-3 my-1">
        <small>网址id:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.url_id" />
      </div>
      <div class="col-md-3 my-1">
        <small>发布时间:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.datetime" />
      </div>
      <div class="col-md-3 my-1">
        <small>是否删除:</small>
        <input type="number" class="form-control form-control-sm" v-model="dataBase.is_delete" />
      </div>
      <div class="col-md-3 my-1">
        <small>封面图:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.img" />
      </div>
      <div class="col-md-3 my-1">
      </div>
      <div class="col-md-3 my-1">
        <small>更多内容:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.more" />
      </div>
      <div class="col-md-3 my-1">
        <small>类别:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.category" />
      </div>
      <hr class="my-3">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h4>编辑本文</h4>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <button 
              type="button" 
              @click="isEditor = !isEditor"
              class="btn btn-sm btn-outline-secondary">切换编辑模式</button>
          </div>
        </div>
      </div>
      <div class="col-md-12 my-1">
        <WangEditor v-show="isEditor"/>
        <textarea v-show="!isEditor" class="form-control form-control-sm" rows="27" v-model="dataBase.context">
        </textarea>
      </div>
      <hr class="my-3">
      <h4>搜索</h4>
      <div class="col-md-6 my-1">
        <small>seo标题:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.seo.title" />
      </div>
      <div class="col-md-3 my-1">
        <small>seo关键词:</small>
        <input type="text" class="form-control form-control-sm" v-model="dataBase.seo.keywords" />
      </div>
      <div class="col-md-12 my-1">
        <small>seo描述:</small>
        <textarea class="form-control form-control-sm" rows="1" v-model="dataBase.seo.description">
        </textarea>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
      <h4>插槽</h4>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button 
            type="button" 
            @click="slotCreate"
            class="btn btn-sm btn-outline-secondary">创建插槽</button>
        </div>
      </div>
    </div>
    <template v-if="dataBase">
    <div v-for="i in dataBase.slot_list" :key="i.id" class="row">
      <div class="col-md-1 my-1">
        <small>顺序:</small>
        <div class="input-group input-group-sm mb-3">
          <input 
            type="number" 
            v-model="i.order"
            class="form-control form-control-sm">
        </div>
      </div>
      <div class="col-md-3 my-1">
        <small>插槽匹配词:</small>
        <div class="input-group input-group-sm mb-3">
          <input 
            type="text" 
            v-model="i.key"
            class="form-control form-control-sm">
          <button 
            class="btn btn-outline-secondary" 
            @click="slotDelete(i.key)"
            type="button">删</button>
        </div>
      </div>
      <div class="col-md-8 my-1">
        <small>插槽匹配值:</small>
        <textarea class="form-control form-control-sm" rows="1" v-model="i.value">
        </textarea>
      </div>
    </div>
    </template>
    <div class="my-5">&nbsp;</div>
    <hr class="my-5">
  </div>
</template>

<script>
import {nanoid} from 'nanoid'
import WangEditor from '../components/WangEditor'
import axios from 'axios'
export default {
  name:'ArticlePage',
  data(){
    return {
      dataBase:null,
      xx1:null,
      isEditor:true,
    }
  },
  components:{
    WangEditor
  },
  props:[
    'object_id'
  ],
  methods:{
    // 获取数据
    getData(){
      this.$bus.$emit('app_send_xx1', this)
      let form = {
        object_id: this.object_id,
        xx1: this.xx1,
        action: 'find',
        count: 'id',
        collection: 'article',
      }
      console.log(form)
      axios.post(this.$api+'/back/mixin',form).then(
        ({data}) => {
          console.log('返回值',data)
          const d = data.data
          this.dataBase = {
            title: d.title || '',
            context: d.context || '',
            datetime: d.datetime || function() {
              const today = new Date();  
              const year = today.getFullYear();
              const month = today.getMonth() + 1;
              const date = today.getDate();
              const curDate = year+"-"+month+"-"+date;
              const hours = today.getHours();  
              let minutes = today.getMinutes();  
              let seconds = today.getSeconds();  
              if(minutes<=9){  
                  minutes="0"+minutes;  
              }if(seconds<=9){  
                  seconds="0"+seconds;
              }
              const myclock = `${hours}:${minutes}:${seconds}`
              return `${curDate} ${myclock}`;
            }(),
            img: d.img || '',
            is_delete: d.is_delete===true ? 1 : 0,
            seo: {
              title:d.seo ? d.seo.title : false || d.seo_title || '',
              keywords:d.seo ? d.seo.keywords : false || d.seo_keywords || '',
              description:d.seo ? d.seo.description : false || d.seo_description || '',
            },
            site_id: d.site_id || '',
            url_id: d.url_id || '',
            more:d.more ? d.more.toString() : '',
            slot_list:d.slot_list ||[],
            category:d.category ||''
          }
          console.log(this.dataBase)
        }
      )
    },
    // 保存到线上
    saveCloud(){
      //获取基础数据，重写状态格式，更多内容，新增日期时间戳格式，新增插槽列表
      let new_dict = {...this.dataBase}
      new_dict.is_delete = new_dict.is_delete === 1
      new_dict.more = new_dict.more.split(',')
      new_dict.category_list = new_dict.category.split(',')
      let dateStr = new_dict.datetime
      dateStr = dateStr.substring(0,19)
      dateStr = dateStr.replace(/-/g,'/')
      new_dict.datetime_ts = new Date(dateStr).getTime()
      new_dict.slot_dict = {}
      //排序
      new_dict.slot_list = this.sortBykey(new_dict.slot_list, 'order')
      for(let i in new_dict.slot_list){
        let slot = new_dict.slot_list[i]
        new_dict.slot_dict[slot.key] = slot.value
      }
      let form = {
        object_id: this.object_id,
        xx1: this.xx1,
        action: 'update',
        count: 'id',
        collection: 'article',
        new_dict
      }
      console.log(form)
      axios.post(this.$api+'/back/mixin',form).then(
        ({data})=>{
          console.log(data)
          if(data.msg){
            alert(data.msg)
          }
        }
      )
    },
    // 删除一个插槽
    slotDelete(key){
      for(let i in this.dataBase.slot_list){
        if(this.dataBase.slot_list[i].key === key){
          this.dataBase.slot_list.splice(i,1)
          break
        }
      }
    },
    //新增一个插槽
    slotCreate(){
      this.dataBase.slot_list.push({
        order:0,
        id:nanoid(),
        key:'',
        value:''
      })
    },
    //排序
    sortBykey(ary, key) { 
      return ary.sort(function (a, b) {
        let x = a[key] 
        let y = b[key] 
        return ((x < y) ? -1 : (x > y) ? 1 : 0) }) 
    }
  },

  mounted(){
    setTimeout(()=>{
      this.getData()
    }, 1)
    this.$bus.$on('page_get_context',(context)=>{
      this.dataBase.context = context
    })
    this.$bus.$on('page_send_context',(obj)=>{
      obj.context = this.dataBase.context
    })
  }
}
</script>

<style>

</style>