<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item" v-for="i in nav_list" :key="i.text">
          <router-link :to="i.path" class="nav-link" active-class='active' aria-current="page">{{i.text}}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name:'BackMenu',
  data(){
    return {
      nav_list:[
        {path:'/back/article_list',text:'文章列表'}
      ]
    }
  }
}
</script>

<style>

</style>