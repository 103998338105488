<template>
  <div id="wang-editor">
    <div id="editor—wrapper">
        <div id="toolbar-container"><!-- 工具栏 --></div>
        <div id="editor-container"><!-- 编辑器 --></div>
    </div>
    <link href="https://unpkg.com/@wangeditor/editor@latest/dist/css/style.css" rel="stylesheet" />
  </div>
</template>

<script>
export default {
  name:'WangEditor',
  data(){
    return {
      context:''
    }
  },
  methods:{
    editorRender(){
      const { createEditor, createToolbar } = window.wangEditor
      const editorConfig = {
          placeholder: 'Type here...',
          onChange:(editor)=>{
            const html = editor.getHtml()
            this.$bus.$emit('page_get_context',html)
          }
      }
      this.$bus.$emit('page_send_context',this)
      const editor = createEditor({
          selector: '#editor-container',
          html: this.context,
          config: editorConfig,
          mode: 'default', // or 'simple'
      })
      const toolbarConfig = {}
      createToolbar({ //const toolbar = 
          editor,
          selector: '#toolbar-container',
          config: toolbarConfig,
          mode: 'default', // or 'simple'
      })
    }
  },
  mounted(){
    setTimeout(()=>{
      this.editorRender()
    }, 1)
  }
}
</script>

<style>
#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 100; /* 按需定义 */
}
#toolbar-container {
  border-bottom: 1px solid #ccc; 
}
#editor-container {
  height: 500px;
}
#wang-editor > .w-e-full-screen-container {
  margin-top: 50px!important;
}
</style>