<template>
  <div></div>
</template>

<script>
import axios from 'axios'
export default {
  name:'HomePage',
  data(){
    return {
      xx1:null
    }
  },
  methods:{
  },
  mounted(){
    this.$bus.$emit('app_send_xx1', this)
    if(!this.xx1){
      this.$router.push({
        name:'LoginPage'
      })
    }
    const form = {
      xx1:this.xx1
    }
    axios.post(this.$api+'/back',form).then(
      ({data}) => {
        if(data.msg & data.msg === '1'){
          this.$router.push({
            name:'BackPage'
          })
        }else{
          this.$router.push({
            name:'LoginPage'
          })
        }
      }
    )
  },
}
</script>

<style>

</style>