<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <slot>欢迎光临</slot>
  </main>
</template>

<script>
export default {
  name:'BackMain'
}
</script>

<style>

</style>