<template>
  <main class="form-signin text-center">
    <form>
      <img class="mb-4" src="https://v5.bootcss.com/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

      <div class="form-floating">
        <input type="text" class="form-control" id="manager_name" v-model="form.manager_name">
        <label for="manager_name">Name</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="manager_pwd" v-model="form.manager_pwd">
        <label for="manager_pwd">Password</label>
      </div>
      <div class="form-floating">
        <input type="text" class="form-control" id="manager_ver" v-model="form.manager_ver">
        <label for="manager_ver">Verification</label>
      </div>

      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me"> Remember me
        </label>
      </div>
      <button class="w-100 btn btn-lg btn-primary" type="button" @click="login">Sign in</button>
      <p class="mt-5 mb-3 text-muted">&copy; 2017–2022</p>
    </form>
    <link href="/static/css/signin.css" rel="stylesheet" />
  </main>
</template>

<script>
import axios from 'axios'
export default {
  name: 'LoginPage',
  data(){
    return {
      form:{
        manager_name:'',
        manager_pwd:'',
        manager_ver:'',
      }
    }
  },
  methods:{
    login(){
      axios.post(this.$api+'/login',this.form).then(
        ({data}) => {
          if(data.xx1){
            localStorage.setItem('xx1', data.xx1)
            this.$bus.$emit('app_get_xx1', data.xx1)
            alert('登录成功')
          }else{
            if(data.msg){
              alert(data.msg)
            }else{
              alert('登录失败')
            }
          }
        }
      )
    },
  },
}
</script>

<style>
</style>
  