<template>
  <div style="width:100%;height:100%;">
    <BackHeader />
    <div class="container-fluid">
      <div class="row">
        <BackMenu />
        <BackMain>
          <keep-alive :include="['ArticleListPage']">
            <router-view></router-view>
          </keep-alive>
        </BackMain>
      </div>
    </div>
    <link rel="stylesheet" href="/static/css/dashboard.css">
  </div>
</template>

<script>
import BackHeader from '../components/BackHeader'
import BackMenu from '../components/BackMenu'
import BackMain from '../components/BackMain'
export default {
  name:'BackPage',
  components:{
    BackHeader, BackMenu, BackMain
  }
}
</script>

<style>
</style>