<template>
  <div class="mt-3">
    <h4>内容管理</h4>
    <hr>
    <div class="row">
      <div class="col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
        <small>站点id:</small>
        <input type="text" class="form-control form-control-sm" v-model="filterBase.site_id" />
      </div>
      <div class="col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
        <small>状态:</small>
        <select 
          class="form-select form-select-sm" 
          aria-label="Default select example" 
          @change="selectChange($event, 'is_delete')"
          v-model="filterBase.is_delete">
          <option value="-1">全部</option>
          <option value="0" seleted>正常状态</option>
          <option value="1">删除状态</option>
        </select>
      </div>
      <div class="col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
        <small>发布时间:</small>
        <div class="input-group input-group-sm">
          <input type="text" class="form-control form-control-sm" v-model="filterBase.datetimeStart">
          <span class="input-group-text">至</span>
          <input type="text" class="form-control form-control-sm" v-model="filterBase.datetimeEnd">
        </div>
      </div>
      <div class="col-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2">
        <small>排序:</small>
        <select 
          class="form-select form-select-sm" 
          @change="selectChange($event, 'sort')"
          aria-label="Default select example" 
          v-model="sort">
          <option value="datetime_ts,1">最早</option>
          <option value="datetime_ts,-1">最新</option>
        </select>
      </div>
    </div>
    <button type="button" class="btn btn-primary btn-sm mt-3" @click="submit">查询</button>
    <table class="table table-hover">
        <thead>
      <tr>
        <th scope="col">站点id</th>
        <th scope="col">网址id</th>
        <th scope="col">标题</th>
        <th scope="col">状态</th>
        <th scope="col">编辑</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="i in tableData" :key="i.id">
        <td>{{i.site_id}}</td>
        <td>{{i.url_id}}</td>
        <td>
          <router-link 
            :to="`/back/article?object_id=${i.id}`" 
            style="color: #333;text-decoration: none;"
            aria-current="page">{{i.title}}</router-link>
        </td>
        <td>
          <template v-if="i.is_delete === true">
            删除
          </template>
          <template v-else-if="i.is_delete === false">
            正常
          </template>
          <template v-else>
            未知
          </template>
        </td>
        <td>
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic outlined example">
            <router-link 
              :to="`/back/article?object_id=${i.id}`" 
              class="btn btn-outline-primary btn-sm py-0">编辑</router-link>
            <button 
              type="button" 
              class="btn btn-outline-primary btn-sm py-0" 
              @click="changeIsDelete(i)">
              <template v-if="i.is_delete === 'loading'">
                ...
              </template>
              <template v-else-if="i.is_delete === false">
                删除
              </template>
              <template v-else>
                恢复
              </template>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name:'ArticleListPage',
  data(){
    return {
      filterBase:{
        site_id:"",
        order:null,
        is_delete:'-1',
        datetimeStart:'',
        datetimeEnd:''
      },
      sort:'datetime_ts,-1',
      xx1:null,
      tableData:[]
    }
  },
  computed:{
    sort_dict(){
      let key = this.sort.split(',')[0]
      let value = this.sort.split(',')[1]
      return {
        key,value
      }
    },
    filter(){
      let filter = {}
      let filterBase = this.filterBase
      if(filterBase){
        if(filterBase.site_id && filterBase.site_id.length > 0){
          filter.site_id = filterBase.site_id
        }
        if(filterBase.is_delete === '1'){
          filter.is_delete = true
        }else if(filterBase.is_delete === '0'){
          filter.is_delete = false
        }
        if(filterBase.datetimeStart.length > 0 || filterBase.datetimeEnd.length > 0){
          filter.datetime_ts = {}
          if(filterBase.datetimeStart != ''){
            let dateStr = filterBase.datetimeStart
            dateStr = dateStr.substring(0,19) + ' 00:00:00.000'
            dateStr = dateStr.replace(/-/g,'/')
            let datetime_ts = new Date(dateStr).getTime()
            filter.datetime_ts.$gte = datetime_ts
          }
          if(filterBase.datetimeEnd != ''){
            let dateStr = filterBase.datetimeEnd
            dateStr = dateStr.substring(0,19) + ' 23:59:59.999'
            dateStr = dateStr.replace(/-/g,'/')
            let datetime_ts = new Date(dateStr).getTime()
            filter.datetime_ts.$lte = datetime_ts
          }
        }
        return filter
      }
      return {}
    },
  },
  methods:{
    //设为删除
    changeIsDelete(article){
      let is_delete = false
      if(article.is_delete === false){
        is_delete = true
      }
      let form = {
        object_id: article.id,
        new_dict:{
          is_delete
        },
        xx1: this.xx1,
        action: 'update',
        count: 'id',
        collection: 'article',
      }
      console.log(form)
      console.log(article)
      article.is_delete = 'loading'
      axios.post(this.$api+'/back/mixin',form).then(
        ({data}) => {
          console.log(article.is_delete)
          article.is_delete = is_delete
          console.log(article.is_delete)
          if(data.msg){
            console.log(data.msg)
          }else{
            alert('没有返回消息')
          }
        }
      )
    },
    //更新选择框
    selectChange(event, obj){
      this.filterBase[obj] = event.target.value
    },
    //提交查询
    submit(){
      this.tableData = []
      this.$bus.$emit('app_send_xx1', this)
      let form = {
        filter: this.filter,
        xx1: this.xx1,
        action: 'find',
        count: 'many',
        collection: 'article',
        field: {
          'context':0,
          'img':0,
          'more':0,
          'seo':0,
          'video':0,
          'video2':0,
        },
        sort_list:[this.sort_dict]
      }
      console.log(form)
      axios.post(this.$api+'/back/mixin',form).then(
        ({data}) => {
          console.log(data)
          this.tableData = data.data
        }
      )
    },
  }
}
</script>

<style>

</style>